import React, { Component } from "react";
import "./RoleCard.scss";
import { notification } from "antd";
import service from "../../services/Service";
import { withLocale } from '../../l10n';
import Loading from "../../utils/loading";
import withNavigation from '../hooks/withNavigation.js';

class KiwiCard extends Component {
  // Preinicializamos el estado de la clase
  state = {
    uuid: "",
    name: "",
    items: [],
    itemName: "",
    lang: this.props.lang,
    loading: false
  };

  changeForm = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSaveData = () => {
    const { navigate } = this.props;
    const urlListaRole = "/users/roles";
    navigate(urlListaRole);
  };

  onSubmit = async (e) => {

    e.preventDefault();
    const { lang } = this.state;
    const roleData = this.state;
    if (!this.props.ident) {
      await service
        .post("roles", roleData)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Rol creado correctamente'),
            });
            this.onSaveData();
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    } else {
      await service
        .put("roles/" + this.state.uuid, roleData)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Rol actualizado correctamente'),
            });
            this.onSaveData();
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  };

  async componentDidMount() {
    
    if (this.props.ident) {
      this.setState({loading: true})
      await service
        .get("roles/" + this.props.ident)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }
          const data = res.data[0];
          this.setState({
            uuid: data.uuid,
            name: data.name,
            loading: false
          });
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  render() {
    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
    var title = "";
    const {lang, loading} = this.state;
    // Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
    if (!this.props.ident) {
      title = lang.handle('Nuevo Rol');
    } else {
      title = lang.handle('Información de Rol') + this.state.name;
    }

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      } else {
        return false;
      }
    }

    return (
      <div className="card">
        {loading && <Loading loading={loading}/>}
        {/* Titulo de la tarjeta */}
        <h4 className="card-title p-3"> {title} </h4>

        {/* Formulario con los datos a cumplimentar */}
        <form onSubmit={this.onSubmit} className="p-3">
          <div className="form-group row">
            <label
              htmlFor="name"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {lang.handle('Nombre')}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={this.changeForm}
                value={this.state.name}
              />
            </div>
          </div>

          <Save save={this.props.editable} />
        </form>
      </div>
    );
  }
}

export default withLocale(withNavigation(KiwiCard));
