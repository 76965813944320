import React, { Component } from "react";
import Loading from "../../utils/loading";
import "./UserCard.scss";
import service from "../../services/Service";
import { notification, Select, Checkbox } from "antd";
import { withLocale } from '../../l10n';
import { hasPrivilege } from "../../api/token";
import withNavigation from '../hooks/withNavigation.js';
import moment from 'moment-timezone';

const { Option } = Select;
class UserCard extends Component {
  // Preinicializamos el estado de la clase
  state = {
    user: {
      isNew: true,
      uuid: "",
      name: "",
      lastname: "",
      username: "",
      email: "",
      tenant_uuid: "",
      role_uuid: "",
      timezone: "",
      active: false,
      password: "",
      repeatPassword: "",
    },
    username: null,
    errors: {
      password: '',
    },
    tenants: [],
    roles: [],
    timezones: [],
    lang: this.props.lang,
    loading: false,
    editPrivilege: false
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    const { lang } = this.state;
    let errors = this.state.errors;
    switch (name) {
      case 'password':
        errors.password = value === this.state.user.repeatPassword
          ? ''
          : lang.handle('¡Las contraseñas deben coincidir!');
        break;
      case 'repeatPassword':
        errors.password = this.state.user.password === value
          ? ''
          : lang.handle('¡Las contraseñas deben coincidir!');
        break;
      default:
        break;
    }
    let user = this.state.user;
    user[name] = value;
    this.setState({ errors, user: user })
  }

  onChangeSelect = (value, e) => {
    if(e.type === "tenant_name") {
      const index_tenant = this.state.tenants.find((item) => item.name === value).uuid;
      let user = this.state.user;
      user.tenant_uuid = index_tenant;

      this.setState({
        [e.type]: value,
        user: user
      })
    } else {
      let user = this.state.user;
      user[e.type] = value;
      this.setState({
        user: user
      })
    }
  };

  onChangeCheck = value => {
    let user = this.state.user;
    user.active = !user.active;
    this.setState({ user: user });
  };

  onSaveData = () => {
    const { navigate } = this.props;
    navigate("/users/list");
  }

  // Funcion que envia la informacion a la base de datos. Si es un usuario nuevo lo crea, si es uno antiguo lo actualiza
  onSubmit = async (e) => {
    e.preventDefault();
    const { lang, username } = this.state;
    const userData = this.state.user;
    const errors = this.state.errors;

    if (userData.isNew && (userData.password === "" || userData.repeatPassword === "")) {
      notification["error"]({
        message: lang.handle('La contraseña es un campo obligatorio'),
      });
    }
    if (!errors.password) {
      if (userData.uuid && !userData.isNew) {
        await service
          .put("users/" + userData.uuid, userData)
          .then(async (res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('Usuario guardado correctamente'),
              });
              await service
                .put("vpn/" + username, { username: userData.username, tenant: userData.tenant_uuid })
                .then((resVPN) => {
                  if (resVPN.data.ok === false && resVPN.data.message) {
                    notification["error"]({
                      message: lang.handle(resVPN.data.message),
                    });
                    return;
                  } else {
                    notification["success"]({
                      message: lang.handle('VPN actualizada correctamente'),
                    });
                  }
                })
                .catch((err) => {
                  console.log("Error devuelto: " + err);
                });
              this.onSaveData();
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      } else {
        await service
          .post("users/sign-up", userData)
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('Usuario creado correctamente'),
              });
              this.onSaveData();
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      }
    } else {
      if (errors.password) {
        notification["error"]({
          message: lang.handle('Las contraseñas deben coincidir'),
        });
      }
    }
  };

  async componentDidMount() {

    const timezones = moment.tz.names();

    const editPrivilege = hasPrivilege('users_edit');

    const uuid = this.props.ident;
    this.setState({ editPrivilege, loading: true, timezones });
    await service.get("users/" + uuid).then((res) => {
      if (res.data.ok === false) {
        notification["error"]({
          message: this.props.lang.handle(res.data.message),
        });
        return;
      }
      if (!res.data[0]) {
        return;
      }

      const user = {
        name: res.data[0].name,
        lastname: res.data[0].lastname,
        username: res.data[0].username,
        email: res.data[0].email,
        tenant_uuid: res.data[0].tenant_uuid,
        role_uuid: res.data[0].role_uuid,
        timezone: res.data[0].timezone,
        active: res.data[0].active,
        uuid: res.data[0].uuid,
        isNew: false,
        password: "",
        repeatPassword: ""
      }

      this.setState({
        user: user,
        username: user.username
      });
    });

    await service.get('tenants')
      .then(res => {
        let tenant = res.data.find(e => e.uuid === this.state.user.tenant_uuid);
        let tenant_name = tenant ? tenant.name : this.props.lang.handle("Sin organización");

        this.setState({
          tenants: res.data,
          tenant_name: tenant_name,
          loading: false
        });
      });

    if (editPrivilege) {
      await service
        .get("roles")
        .then(res => {
          this.setState({
            roles: res.data
          })
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  render() {
    const { errors, tenants, roles, timezones, editPrivilege, loading } = this.state;
    const { isNew } = this.state.user;
    const { lang } = this.state;
    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
		var title = "";

		// Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
		if (isNew) {
			title = lang.handle('Nuevo usuario');
		}
		else {
			title = lang.handle('Editando usuario') + ": " + this.state.user.username;
		};

    return (
      <div className="card ">
        {loading && <Loading loading={loading}/>}
        <h4 className="p-3"> {title} </h4>
        <form onSubmit={this.onSubmit} className="p-3">
          <div className="form-group row">
            <label
              htmlFor="name"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Nombre')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="name"
                id="name"
                name="name"
                value={this.state.user.name}
                onChange={this.onInputChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="lastname"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Apellidos')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="lastname"
                name="lastname"
                className="form-control"
                id="lastname"
                value={this.state.user.lastname}
                onChange={this.onInputChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="username"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle("Nombre de usuario")}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="username"
                name="username"
                className="form-control"
                id="username"
                value={this.state.user.username}
                onChange={this.onInputChange}
                required
                disabled={!editPrivilege}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="email"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Correo electrónico')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                name="email"
                className="form-control"
                id="email"
                value={this.state.user.email}
                onChange={this.onInputChange}
                required
              />
            </div>
          </div>
          {isNew && <div className="form-group row">
            <label
              htmlFor="password"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Contraseña')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                name="password"
                className="form-control"
                id="password"
                value={this.state.user.password}
                onChange={this.onInputChange}
                required
              />
            </div>
          </div>}
          {isNew && <div className="form-group row">
            <label
              htmlFor="repeatPassword"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Repetir contraseña')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                name="repeatPassword"
                className="form-control"
                id="repeatPassword"
                value={this.state.user.repeatPassword}
                onChange={this.onInputChange}
                required
              />
              {errors.password.length > 0 && <span className='boardFormError'>{errors.password}</span>}
            </div>
          </div>}
          <div className="form-group row">
            <label
              htmlFor="tenant_uuid"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Organización')}{" "}
            </label>
            <div className="col-sm-10">
              <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="tenant_uuid"
                onSelect={(value, event) => this.onChangeSelect(value, event)}
                name="tenant_uuid"
                value={this.state.tenant_name}
                className="register-form__select form-select-input form-control"
                id="tenantSelect"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={!editPrivilege}
              >
                {tenants.map((tenant) => {
                  return (
                    <Option key={tenant.uuid} type="tenant_name" value={tenant.name}>{tenant.name}</Option>
                  )
                })}
              </Select>
            </div>
          </div>
          {editPrivilege && <div className="form-group row">
            <label
              htmlFor="role_uuid"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Nivel')}{" "}
            </label>
            <div className="col-sm-10">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={lang.handle('Selecciona un rol')}
                optionFilterProp="role_uuid"
                onSelect={(value, event) => this.onChangeSelect(value, event)}
                name="role_uuid"
                value={this.state.user.role_uuid}
                className="register-form__select form-control"
              >
                {roles.map((option) => (
                  <Option type='role_uuid' key={option.uuid} value={option.uuid}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          }
          <div className="form-group row">
            <label
              htmlFor="timezone"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {lang.handle('Zona horaria')}
            </label>
            <div className="col-sm-10">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={lang.handle('Seleccione una zona horaria')}
                onSelect={this.onChangeSelect}
                name="timezone"
                value={this.state.user.timezone}
                className="register-form__select form-control"
              >
                {timezones.map((option) => (
                  <Option type='timezone' key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {editPrivilege && <div className="form-group row">
            <label
              htmlFor="active"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {" "}
            </label>
            <div className="col-sm-10">
              <Checkbox
                name="active"
                checked={this.state.user.active}
                onClick={this.onChangeCheck}>
                {lang.handle('Activo')}
              </Checkbox>
            </div>
          </div>
          }
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        </form>
      </div>
    );
  }
}

export default withLocale(withNavigation(UserCard));
