import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ACCESS_TOKEN, REFRESH_TOKEN, API_TOKEN, PRIVILEGES_TOKEN } from "../../utils/constants";
import { signInApi } from "../../api/user";
import { getAccessToken } from "../../api/token";
import { withLocale } from "../../l10n";
import "./LoginForm.scss";

function LoginForm(props) {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    username: '',
    password: ''
  });

  const onChange = loginValues => {
    setInputs({
      ...inputs,
      [loginValues.target.name]: loginValues.target.value,
    });
  };

  const onLogin = async (loginValues) => {
    
    const username = inputs.username;
    const password = inputs.password;
    if (!password || !username) {
      notification["error"]({
        message: props.lang.handle('Complete los campos'),
      });
    } else {
      const result = await signInApi(inputs);
      if (!result.ok) {
        notification["error"]({
          message: props.lang.handle(result.message),
        });
      } else {
        localStorage.setItem(ACCESS_TOKEN, result.accessToken);
        localStorage.setItem(REFRESH_TOKEN, result.refreshToken);
        localStorage.setItem(API_TOKEN, result.apiToken);
        localStorage.setItem(PRIVILEGES_TOKEN, result.privilegesToken);

        notification["success"]({
          message: props.lang.handle('Inicio de sesión exitoso'),
        });
        navigate(0);
      }
    }
  };

  if (getAccessToken()) {
    return (navigate(0));
  }

  return (
    <Form
      className="login-form"

      onChange={onChange}
      onFinish={onLogin}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: props.lang.handle('Introduzca un nombre de usuario'),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          name="username"
          type="username"
          placeholder={props.lang.handle("Nombre de usuario") + "."} 
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: props.lang.handle('Introduzca una contraseña') + ".",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          name="password"
          type="password"
          placeholder={props.lang.handle('Contraseña')}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
        {props.lang.handle('Iniciar sesión')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withLocale(LoginForm);
