import React, { Component } from "react";
import "./ManufactureBoard.scss";
import { notification, DatePicker, Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { uuidValidation, macValidation } from "../../../utils/formValidation";
import service from "../../../services/Service";
import moment from "moment";
import dayjs from 'dayjs';
import { withLocale } from "../../../l10n";
import Loading from "../../../utils/loading";
import withNavigation from '../../../components/hooks/withNavigation';

class ManufactureBoard extends Component {
  // Se inicia el vector de estados del componente
  state = {
    ki_id: "",
    uuid: "",
    loading: false,
    mac: "",
    date: new Date(),
    model: "",
    notes: "",
    state: "Fabricado",
    errors: {
      uuid: "",
      mac: "",
    },
    lang: this.props.lang,
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    const { lang } = this.state;
    let errors = this.state.errors;
    switch (name) {
      case "uuid":
        errors.uuid = uuidValidation(value) ? "" : '¡' + lang.handle('UUID erróneo') + '!';
        break;
      case "mac":
        errors.mac = macValidation(value) ? "" : '¡' + lang.handle('MAC errónea') + '!';
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  // Funcion que guarda el valor del modelo y el usb en el formulario
  onModelChange = (e) => {
    const { lang } = this.state;
    switch (e.target.value) {
      case "Kibox0":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "Teltonika RUT200",
          usb: lang.handle('Ninguno'),
        });
        break;

      case "Kibox1":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
          usb: "FTDI",
        });
        break;

      case "Kibox1A":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "Teltonika TRB145",
          usb: lang.handle('Ninguno'),
        });
        break;

      case "Kibox1E":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "Teltonika TRB245",
          usb: lang.handle('Ninguno'),
        });
        break;

      case "Kibox2":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
          usb: "SHIELD-V1.0.1-2P",
        });
        break;

      case "Kibox2A":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "Teltonika RUT906",
          usb: lang.handle('Ninguno'),
        });
        break;

      case "Kibox4":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
          usb: "SHIELD-V1.0.1-4P",
        });
        break;

      case "Kibox8":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
          usb: "USB-COM4",
        });
        break;

      case "Kibox-Lite":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "RT5350F-OLinuXino-EVB",
          usb: "FTDI",
        });
        break;

      case "Kibox-CAN":
        this.setState({
          [e.target.name]: e.target.value,
          hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
          usb: "SHIELD-V1.0.1-CAN",
        });
        break;

      default:
        this.setState({
          [e.target.name]: "",
          hardware_version: lang.handle('error'),
          usb: lang.handle('error'),
        });
        break;
    }
  };

  // Funcion que guarda el valor escogido en el calendario dentro del campo correspondiente de state
  onChangeDate = (date, dateString) => {
    this.setState({ date: dateString });
  };

  // Funcion que envia la informacion a la base de datos. Si es una placa nueva la crea, si es una antigua la actualiza
  onSubmit = async (e) => {
    // Se evita cargar el valor por defecto del state
    e.preventDefault();
    const boardData = this.state;
    const errors = this.state.errors;
    const { lang } = this.state;

    if (boardData.date instanceof Date) {
      boardData.date = moment(this.state.date).format("DD-MM-YYYY");
    }

    // Se guarda el estado del componente en una variable para enviarla a la base de datos
    if (!errors.uuid && !errors.mac) {
      await service
        .get("boards")
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            if (res.data.find((item) => item.uuid === boardData.uuid && item.ki_id !== boardData.ki_id)) {
              Modal.confirm({
                title: lang.handle("UUID de placa ya en uso"),
                content: lang.handle("Habrá mas de una placa con ese uuid.¿Está seguro de esto?"),
                icon: <ExclamationCircleOutlined />,
                onOk: () => { this.save(boardData) },
              });
            } else {
              this.save(boardData);
            }
          }
        })
        .catch((err) => {
          console.log("Error: " + err);
        });
    } else {
      if (errors.mac) {
        notification["error"]({
          message: lang.handle('MAC errónea'),
        });
      }
      if (errors.uuid) {
        notification["error"]({
          message: lang.handle('UUID erróneo'),
        });
      }
    }
  };

  async save(boardData) {
    const urlListaKibox = "/boards/manufacture/list/1";
    const { navigate } = this.props;
    const { lang } = this.state;

    if (this.props.newBoard) {
      await service
        .post("boards", boardData)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Placa creada correctamente'),
            });
            navigate(urlListaKibox);
          }
        })
        .catch((err) => {
          console.log("Error: " + err);
        });
    }
    // Si es una placa recuperada, se actualiza su valor
    else {
      await service
        .put("boards/" + this.props.ident, boardData)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Placa actualizada correctamente'),
            });
            navigate(urlListaKibox);
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  // Funcion que carga la información recogida de la base de datos en el componente
  async componentDidMount() {
    // Si es una placa cargada desde el listado de instalaciones, se obtiene mediante su uuid
    if (this.props.ident) {
      this.setState({ loading: true })
      await service
        .get("boards/" + this.props.ident)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }
          const board = res.data;
          this.setState({
            ki_id: board.ki_id,
            uuid: board.uuid,
            hardware_version: board.hardware_version,
            mac: board.mac,
            date: new Date(board.date),
            model: board.model,
            usb: board.usb,
            state: board.state,
            notes: board.notes,
            _id: board._id,
            loading: false
          });
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  // Se genera el componente
  render() {
    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
    var title = "";
    const { lang } = this.state;
    // Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
    if (this.props.newBoard === true) {
      title = lang.handle('Nuevo kibox');
    } else {
      title = lang.handle('Información de kibox') + ": " + this.state.ki_id;
    }

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      } else {
        return null;
      }
    }

    const { errors, loading } = this.state;
    return (
      <div className="card">
        {loading && <Loading loading={loading} />}
        <h4 className="p-3"> {title} </h4>
        <form onSubmit={this.onSubmit} className="p-3">
          {/* UUID placa */}
          <div className="form-group row">
            <label
              htmlFor="uuid"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              UUID{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="uuid"
                placeholder="37555f8a-1cab-486b-ab2e-1c65e80b2c12"
                onChange={this.onInputChange}
                name="uuid"
                value={this.state.uuid}
                disabled={!this.props.editable}
                required
              />
              {errors.uuid.length > 0 && (
                <span className="boardFormError">{errors.uuid}</span>
              )}
            </div>
          </div>

          {/* MAC */}
          <div className="form-group row">
            <label
              htmlFor="mac"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              MAC{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="mac"
                placeholder="30:A4:F5:20:4C:5A"
                onChange={this.onInputChange}
                name="mac"
                value={this.state.mac}
                disabled={!this.props.editable}
                required
              />
              {errors.mac.length > 0 && (
                <span className="boardFormError">{errors.mac}</span>
              )}
            </div>
          </div>

          {/* Fecha Placa */}
          <div className="form-group row">
            <div className="col newCard-form-text"> {lang.handle('Fecha fabricación')} </div>
            <div className="col-sm-10">
              <DatePicker
                className="form-control"
                value={dayjs(moment(this.state.date, "DD-MM-YYYY"))}
                onChange={this.onChangeDate}
                format="DD-MM-YYYY"
                disabled={!this.props.editable}
                required
              />
            </div>
          </div>

          <div className="form-group row">
            {/* Modelo de la placa */}
            <div className="col newCard-form-text"> {lang.handle('Modelo placa')} </div>
            <div className="col-sm-10">
              <select
                className="form-control"
                onChange={this.onModelChange}
                name="model"
                value={this.state.model}
                disabled={!this.props.editable}
                required
              >
                <option value=""> -- {lang.handle('Seleccionar opción')} -- </option>
                <option value="Kibox0"> Kibox0 </option>
                <option value="Kibox1"> Kibox1 </option>
                <option value="Kibox1A"> Kibox1A </option>
                <option value="Kibox1E"> Kibox1E </option>
                <option value="Kibox2"> Kibox2 </option>
                <option value="Kibox2A"> Kibox2A </option>
                <option value="Kibox4"> Kibox4 </option>
                <option value="Kibox8"> Kibox8 </option>
                <option value="Kibox-Lite"> Kibox-Lite </option>
                <option value="Kibox-CAN"> Kibox-CAN </option>
              </select>
            </div>
          </div>

          {/* Notas */}
          <div className="form-group row">
            <label
              htmlFor="notes"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Notas')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="notes"
                placeholder={lang.handle('Tarda demasiado en encenderse')}
                onChange={this.onInputChange}
                name="notes"
                value={this.state.notes}
                disabled={!this.props.editable}
              />
            </div>
          </div>

          <Save save={this.props.editable} />
        </form>
      </div>
    );
  }
}

export default withLocale(withNavigation(ManufactureBoard));
