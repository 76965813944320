import React, { Component } from "react";
import { Button, Space, Tag } from "antd";
import { InfoOutlined } from "@ant-design/icons";
import BotonIrKiconex from "../BotonIrKiconex/BotonIrKiconex";
import service from "../../services/Service";
import { withLocale } from "../../l10n";
import DataTable from "../DataTable/DataTable";
import { GetColumnLiteralSearchProps } from '../../utils/functions';
import withNavigation from '../hooks/withNavigation.js';

class DevicesList extends Component {
  state = {
    insta: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    selectedUUID: "",
    selectedICC: "",
    lang: this.props.lang
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await service
      .get("devices")
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            insta: res.data,
          });
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  }

  detallesPlaca = () => {
    const { navigate } = this.props;
    navigate(`/devices/details/${this.state.selectedUUID}`);
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0]._id,
          selectedUUID: selectedRows[0].uuid,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { lang, insta, loading } = this.state;
    const columns = [
      {
        title: lang.handle('Nombre'),
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...GetColumnLiteralSearchProps('name', lang)
      },
      {
        title: lang.handle('Descripción'),
        dataIndex: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
        ...GetColumnLiteralSearchProps('description', lang)
      },
      {
        title: lang.handle('UUID placa'),
        dataIndex: "device_uuid",
        ...GetColumnLiteralSearchProps('device_uuid', lang)
      },
      {
        title: lang.handle('Estado instalación'),
        dataIndex: ["status", "link", "detected"],
        render: function (data) {
          if (data === true) {
            return <Tag color="green"> {lang.handle('Conectada')} </Tag>;
          } else {
            return <Tag color="red"> {lang.handle('Desconectada')} </Tag>;
          }
        },
        filters: [
          { text: lang.handle('Conectada'), value: true },
          { text: lang.handle('Desconectada'), value: false },
        ],
        onFilter: (value, record) => {
          const valueCompare = record.status.link.detected ?? false;
          return valueCompare.toString().indexOf(value) === 0
        }
      },
      {
        title: lang.handle('Habilitación'),
        dataIndex: "enabled",
        render: function (data) {
          if (data === true) {
            return <Tag color="green"> {lang.handle('Habilitada')} </Tag>;
          } else {
            return <Tag color="red"> {lang.handle('Deshabilitada')}</Tag>;
          }
        },
        filters: [
          { text: lang.handle('Habilitada'), value: true },
          { text: lang.handle('Deshabilitada'), value: false },
        ],
        onFilter: (value, record) =>
          record.enabled.toString().indexOf(value) === 0,
      },
    ];
    return (
      <div>
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={columns}
          rowKey="device_uuid"
          dataSource={insta}
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            <Button
              type="primary"
              icon={<InfoOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.detallesPlaca}
            >
              {lang.handle('Detalles')}
            </Button>
            <BotonIrKiconex
              disabled={this.state.botonDisabled}
              uuid={this.state.selectedUUID}
            />
          </Space>
        </div>
      </div>
    );
  }
}

export default withLocale(withNavigation(DevicesList));
