import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getTokenInfo } from "../../api/token";
import './SignIn.scss';
import LogoKiconex from '../../assets/img/png/logo.png';
import LoginForm from '../../components/LoginForm';

export default function SignIn() {
  const navigate = useNavigate();
  const role_uuid = getTokenInfo().role_uuid;

  useEffect(() => {
    if (role_uuid) {
      navigate("/");
    }
  }, [navigate, role_uuid])

  if (!role_uuid) {
    return (
      <div className="signInForm">
        <img
          className="login-logo"
          src={LogoKiconex}
          alt="Kiconex"
        />
        <LoginForm />
      </div>
    )
  }
}
