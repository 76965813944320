import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./BoardCard.scss";
import { notification, Select, Divider, DatePicker, Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  uuidValidation,
  macValidation,
  lengthValidation,
  typeValidation,
  dateStringValidation,
} from "../../utils/formValidation";
import getPlansFormatted from "../../utils/getPlansFormatted";
import service from "../../services/Service";
import moment from "moment";
import dayjs from 'dayjs';
import { Tabs } from "antd";
import { hasPrivilege } from "../../api/token";
import { withLocale } from "../../l10n/index.js";
import { STATE_ACTIVE, STATE_CANCELLED, STATE_INACTIVE, STATE_NOT_APPLICABLE, SUBSCRIPTIONS } from "../../utils/constants";
import Loading from "../../utils/loading";
import withNavigation from '../hooks/withNavigation.js';

const { Option } = Select;

class SubscriptionsExtra {
  constructor(data = {}) {
    SUBSCRIPTIONS.forEach(s => {
      this[s] = data && data[s] ? data[s] : {
        state: s !== 'm2m' ? STATE_NOT_APPLICABLE : null,
        hiring_date: "",
        renewal_date: "",
        renewal_interval: ""
      }
    });
  }
}

class BoardCard extends Component {
  // Preinicializamos el estado de la clase
  state = {
    board: {
      ki_id: 0,
      order_id: '',
      name: '',
      tenant_uuid: '',
      final_client: '',
      uuid: '',
      serial_number: '',
      work_order: '',
      hardware_version: "A20-OLinuXino-LIME2-e16Gs16M",
      mac: '',
      date: new Date(),
      ki_subscription_state: null,
      ki_connection_date: null,
      renewal_date: null,
      renewal_interval: null,
      model: 'Kibox2',
      usb: 'FTDI',
      subscription: 'Professional',
      devices_number: '8',
      panel: 0,
      cable: 0,
      kiwi: 'Ninguno',
      icc: '',
      ki_router_sn: '',
      optionals: '',
      notes: '',
      state: 'Asignado',
      comercial_info: {
        order_id: '',
        name: '',
        serial_number: '',
        state: 'Stock',
        model: ''
      },
      subscriptions_extra: new SubscriptionsExtra(),
    },
    errors: {
      uuid: '',
      mac: '',
      sn: '',
      wo: '',
      icc: '',
      ki_router_sn: '',
    },
    ki_plans: {
      name: [],
      uuid: [],
    },
    tenants: [],
    work_orders: [],
    work_orders_original_length: [],
    select_loading: true,
    loading: false,
    lang: this.props.lang
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    const levels = name.split('.');

    let errors = this.state.errors;
    const { lang } = this.state;
    switch (name) {
      case 'uuid':
        errors.uuid = uuidValidation(value) ? '' : lang.handle('¡UUID erróneo!');
        break;
      case 'mac':
        errors.mac = macValidation(value) ? '' : lang.handle('¡MAC errónea!');
        break;
      case 'serial_number':
        errors.sn = lengthValidation(value, 14) ? '' : lang.handle('¡Número de serie erróneo!');
        break;
      case 'icc':
        errors.icc = lengthValidation(value, 19) ? '' : lang.handle('¡ICC erróneo!');
        break;
      case 'ki_router_sn':
        errors.ki_router_sn = dateStringValidation(value) || value === "" ? '' : lang.handle('¡Número de serie erróneo!');
        break;
      default:
        break;
    }

    this.setState({ errors })

    let board = this.state.board;
    if (levels[0] === 'comercial_info') {
      board.comercial_info[levels[1]] = value;
    } else if (levels[0] === 'subscriptions_extra') {
      board.subscriptions_extra[levels[1]][levels[2]] = value;
    } else {
      board[name] = value;
    }
    this.setState({ board: board });

    if (name === 'state') {
      if (value === 'Fabricado') {
        this.clearTenant();
      }
    }
  }

  clearTenant = () => {
    this.setState({ tenant_uuid: null, tenant_name: this.props.lang.handle("Sin organización") });
  }

  // Funcion que guarda el valor escogido en el calendario dentro del campo correspondiente de state
  onChangeDate = (date, dateString) => {
    let board = this.state.board;
    board.date = dateString;
    this.setState({ board: board });
  };
  onChangeDateConnection = (date, dateString) => {
    let board = this.state.board;
    board.ki_connection_date = dateString;
    this.setState({ board: board });
  };
  onChangeDateRenewal = (date, dateString) => {
    let board = this.state.board;
    board.renewal_date = dateString;
    this.setState({ board: board });
  };

  onChangeDateSubscriptions = (date, dateString, key, level) => {
    let board = this.state.board;
    board.subscriptions_extra[key][level] = dateString;
    this.setState({ board: board });
  };

  onChangeSelect = (value, e) => {
    if (e.type === 'ki_subscription_name') {
      var index_plan = this.state.ki_plans.name.indexOf(value);
      let board = this.state.board;
      board.ki_subscription_name = value;
      board.ki_subscription_uuid = this.state.ki_plans.uuid[index_plan];

      this.setState({
        board: board
      })
    } else if (e.type === "tenant_name") {
      const index_tenant = this.state.tenants.find((item) => item.name === value).uuid;
      let board = this.state.board;
      board.tenant_uuid = index_tenant;

      this.setState({
        [e.type]: value,
        board: board
      })
    }
  };

  onChangeKiSubscriptionState = (state) => {
    let board = this.state.board;
    board.ki_subscription_state = state;
    if (state === STATE_NOT_APPLICABLE) {
      board.renewal_interval = 0;
    }
    this.setState({
      board
    })
  };

  onChangeSubscriptionExtraState = (type, state) => {
    let board = this.state.board;
    board.subscriptions_extra[type].state = state;
    if (state === STATE_NOT_APPLICABLE) {
      board.subscriptions_extra[type].renewal_interval = 0;
    }
    this.setState({
      board
    });
  };

  onSaveData = () => {
    const urlListaPlacas = "/boards/list";
    const urlPlacasManufactured = "/boards/manufacture/list";
    const urlPlacasAssigned = "/boards/manufacture/assigned";
    const state = this.state.board.state;
    const { navigate } = this.props;
    switch (state) {
      case "Fabricado":
        navigate(urlPlacasManufactured);
        break;
      case "Asignado":
        navigate(urlPlacasAssigned);
        break;
      case "Terminado":
      default:
        navigate(urlListaPlacas);
        break;
    }
  }

  validate = () => {
    let valid = true;
    const { board, lang } = this.state;

    if (
      !board.ki_subscription_state ||
      ([STATE_INACTIVE, STATE_ACTIVE].includes(board.ki_subscription_state) && (!board.renewal_interval || board.renewal_interval === "0"))
    ) {
      notification["error"]({
        message: lang.handle('Estado de suscripción no compatible con el intervalo seleccionado'),
      });
      valid = false;
    }

    SUBSCRIPTIONS.forEach(extra => {
      if (
        (extra === 'm2m' && !board.subscriptions_extra[extra].state) ||
        ([STATE_INACTIVE, STATE_ACTIVE].includes(board.subscriptions_extra[extra].state) && (!board.subscriptions_extra[extra].renewal_interval || board.subscriptions_extra[extra].renewal_interval === "0"))
      ) {
        notification["error"]({
          message: lang.handle('Estado de suscripción no compatible con el intervalo seleccionado'),
        });
        valid = false;
      }
    });

    return valid;
  }

  handleSearchWorkOrder = (v) => {
    let work_orders = this.state.work_orders;

    if (this.state.work_orders_original_length !== work_orders.length) {
      work_orders.pop();
    }

    if (!work_orders.find(e => e.value.toString().indexOf(v) >= 0) && v) {
      work_orders.push({ label: v, value: v })
    }
  }

  handleChangeWorkOrder = (v) => {
    let errors = this.state.errors;
    const { lang } = this.state;
    errors.wo = lengthValidation(v, 8) && typeValidation(v, 'number') ? '' : lang.handle('¡Orden de trabajo erronea!');

    let board = this.state.board;
    board.work_order = v;

    if (!board.serial_number) {
      board.serial_number = this.state.work_orders.find(e => e.value === v)?.serialNumber ?? '';
    }

    this.setState({ board })
  }

  // Funcion que envia la informacion a la base de datos. Si es una placa nueva la crea, si es una antigua la actualiza
  onSubmit = async (e) => {
    e.preventDefault();
    const valid = this.validate();
    if (!valid) {
      return;
    }
    const boardData = this.state.board;
    const { errors, lang } = this.state;

    if (boardData.date instanceof Date) {
      boardData.date = moment(this.state.board.date).format("DD-MM-YYYY");
    }
    if (boardData.ki_connection_date instanceof Date) {
      boardData.ki_connection_date = moment(this.state.board.ki_connection_date).format("DD-MM-YYYY");
    }

    if (boardData.renewal_date instanceof Date) {
      boardData.renewal_date = moment(this.state.board.renewal_date).format("DD-MM-YYYY");
    }

    if (errors.uuid || errors.mac || errors.sn || errors.wo || errors.icc || errors.ki_router_sn) {
      notification["error"]({
        message: lang.handle('Rellene el formulario correctamente'),
      });
      return;
    }

    await service
      .get("boards")
      .then((res) => {
        if (res.data.ok === false) {
          notification.error({
            message: lang.handle(res.data.message),
          });
          return;
        }

        if (res.data.find((item) => item.uuid === boardData.uuid && item.ki_id !== boardData.ki_id)) {
          Modal.confirm({
            title: lang.handle("UUID de placa ya en uso"),
            content: lang.handle("Habrá mas de una placa con ese uuid.¿Está seguro de esto?"),
            icon: <ExclamationCircleOutlined />,
            onOk: () => { this.save(boardData) },
          });
        } else {
          this.save(boardData);
        }

      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });

  }

  async save(boardData) {
    const { lang } = this.state;

    if (this.props.newBoard) {
      await service.post('boards', boardData)
        .then(res => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Placa creada correctamente'),
            });
            this.onSaveData();
          }
        })
        .catch(err => {
          console.log('Error devuelto: ' + err);
        });
    }
    else {
      await service.put('boards/' + this.state.ki_id, boardData)
        .then(res => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Placa actualizada correctamente'),
            });
            this.onSaveData();
          }
        })
        .catch(err => {
          console.log('Error devuelto: ' + err);
        });
    }
  }

  // Funcion que se ejecuta cuando el componente se ha montado
  async componentDidMount() {
    this.setState({
      select_loading: true,
      loading: true
    })

    await getPlansFormatted()
      .then(res => {
        this.setState({
          ki_plans: {
            name: res[0],
            uuid: res[1],
          }, select_loading: false,
        });
        return;
      });

    await service.get('orders/wo', { pending: true })
      .then(res => {
        this.setState({ work_orders: res.data.map((item) => ({ label: item.wo, value: item.wo, serialNumber: item.serialNumber })), work_orders_original_length: res.data.length });
      });

    await service.get('tenants')
      .then(res => {
        this.setState({ tenants: res.data })
      });
    // Si se está accediendo desde asignacion o edición de componente
    if (this.props.ident) {
      const url = this.props.details ? "boards/uuid/" : "boards/";
      await service.get(url + this.props.ident)
        .then(res => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }
          const data = res.data;

          let tenant = this.state.tenants.find(e => e.uuid === data.tenant_uuid);
          let tenant_name = tenant ? tenant.name : this.props.lang.handle("Sin organización");

          const board = {
            ki_id: data.ki_id,
            order_id: data.order_id ?? '',
            name: data.name ?? '',
            tenant_uuid: data.tenant_uuid ?? '',
            final_client: data.final_client ?? '',
            uuid: data.uuid,
            serial_number: data.serial_number ?? '',
            work_order: data.work_order ?? '',
            hardware_version: data.hardware_version,
            mac: data.mac,
            date: new Date(data.date),
            ki_connection_date: data.ki_connection_date ? new Date(data.ki_connection_date) : null,
            renewal_date: data.renewal_date ? new Date(data.renewal_date) : null,
            renewal_interval: data.renewal_interval ?? "",
            ki_subscription_state: data.ki_subscription_state ?? null,
            model: data.model,
            usb: data.usb,
            subscription: data.subscription ?? 'Professional',
            ki_subscription_name: data.ki_subscription_name ?? '',
            ki_subscription_uuid: data.ki_subscription_uuid ?? '',
            devices_number: data.devices_number ?? '8',
            panel: data.panel ?? 0,
            cable: data.cable ?? 0,
            kiwi: data.kiwi ?? 'Ninguno',
            icc: data.icc ?? '',
            ki_router_sn: data.ki_router_sn ?? '',
            optionals: data.optionals ?? '',
            notes: data.notes,
            state: this.props.layer === 'assign' ? 'Asignado' : data.state,
            comercial_info: {
              order_id: data.comercial_info?.order_id ?? "",
              name: data.comercial_info?.name ?? "",
              serial_number: data.comercial_info?.serial_number ?? "",
              state: data.comercial_info?.state?.trim() || "Stock",
              model: data.comercial_info?.model ?? ""
            },
	    subscriptions_extra: new SubscriptionsExtra(data.subscriptions_extra)
          }

          this.setState({
            board: board,
            tenant_name: tenant_name,
            editing: true,
            loading: false
          });
        })
        .catch(err => {
          console.log('Error devuelto: ' + err);
        })
    }
    this.setState({ loading: false });
  }

  render() {
    const { lang } = this.state;
    const { ki_plans, errors, tenants, work_orders } = this.state;

    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
    var title = "";

    // Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
    if (this.props.newBoard === true) {
      title = lang.handle('Nuevo kibox');
    }
    else {
      title = lang.handle('Información del kibox') + ":  " + this.state.board.ki_id;
    };

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button id='button-save' className="btn btn-primary d-flex float-right" type='submit'>
            <div className="btnText">
              <p>{lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      }
      else {
        return null
      }
    }

    const kiconex_editable = this.props.editable && hasPrivilege('administration_edit');

    const kiconexTab = {
      label: lang.handle('General'), key: '1', children: <div>
        {/* Numero de pedido */}
        <div className="form-group row">
          <label htmlFor="order_id" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Pedido')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="order_id"
              placeholder="20PED4596"
              onChange={this.onInputChange}
              name="order_id"
              value={this.state.board.order_id}
              disabled={!kiconex_editable}
              required
            />
          </div>
        </div>

        {/* Tenant */}
        <div className="form-group row">
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Organización')} </div>
          <div className="col-sm-10">
            <Select
              disabled={!kiconex_editable}
              showSearch
              allowClear
              onClear={this.clearTenant}
              className="form-control"
              optionFilterProp="tenant_uuid"
              value={this.state.tenant_name}
              onSelect={(value, event) => this.onChangeSelect(value, event)}
              filterOption={(input, option) =>
                String(option.value).toLowerCase().indexOf(String(input).toLowerCase()) >= 0
              }
              name="tenant_uuid"
              dropdownRender={options => (
                <div>
                  {options}
                  <Divider style={{ margin: '4px 0' }} />
                </div>
              )}
            >
              {tenants.map((tenant) => {
                return (
                  <Option key={tenant.uuid} type="tenant_name" value={tenant.name}>{tenant.name}</Option>
                )
              })}
            </Select>
          </div>
        </div>

        {/* UUID placa */}
        <div className="form-group row">
          <label htmlFor="uuid" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('UUID')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="uuid"
              placeholder="37555f8a-1cab-486b-ab2e-1c65e80b2c12"
              onChange={this.onInputChange}
              name="uuid"
              value={this.state.board.uuid}
              disabled={!kiconex_editable}
              required
            />
            {errors.uuid.length > 0 && <span className='boardFormError'>{errors.uuid}</span>}
          </div>
        </div>

        {/* Nombre de instalacion */}
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Nombre instalación')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={this.state.board.name}
              disabled
            />
          </div>
        </div>

        {/* Nº serie */}
        <div className="form-group row">
          <label htmlFor="serial_number" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Nº serie')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="serial_number"
              placeholder="202005080005"
              onChange={this.onInputChange}
              name="serial_number"
              value={this.state.board.serial_number}
              disabled={!kiconex_editable}
            />
            {errors.sn.length > 0 && <span className='boardFormError'>{errors.sn}</span>}
          </div>
        </div>

        {/* Orden de trabajo */}
        <div className="form-group row">
          <label htmlFor="work_order" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Orden de trabajo')} </label>
          <div className="col-sm-10">
            <Select
              className="form-control"
              onChange={this.handleChangeWorkOrder}
              id="work_order"
              name="work_order"
              value={this.state.board.work_order}
              disabled={!kiconex_editable}
              options={work_orders}
              showSearch
              onSearch={this.handleSearchWorkOrder}
              filterOption={(input, option) => {
                const strOption = option.label.toString();
                return strOption.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            />
            {errors.wo.length > 0 && <span className='boardFormError'>{errors.wo}</span>}
          </div>
        </div>

        {/* Version hardware kiconex */}
        <div className="form-group row">
          <div className="col newCard-form-text"> {lang.handle('Versión hardware')} </div>
          <div className="col-sm-10">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="hardware_version"
              value={this.state.board.hardware_version}
              disabled={!kiconex_editable}
              required>
              <option value="A20-OLinuXino-LIME2-e16Gs16M" defaultValue> A20-OLinuXino-LIME2-e16Gs16M </option>
              <option value="A20-OlinuXino-LIME2-e4GB"> A20-OlinuXino-LIME2-e4GB </option>
              <option value="A20-OLinuXino-LIME2-e4Gs16M"> A20-OLinuXino-LIME2-e4Gs16M </option> 
              <option value="Teltonika RUT200"> Teltonika RUT200 </option>
              <option value="Teltonika RUT230"> Teltonika RUT230 </option>
              <option value="Teltonika RUT906"> Teltonika RUT906 </option>
              <option value="Teltonika RUT955"> Teltonika RUT955 </option>
              <option value="Teltonika RUT956"> Teltonika RUT956 </option>
              <option value="Teltonika TRB145"> Teltonika TRB145 </option>
            </select>
          </div>
        </div>

        {/* MAC */}
        <div className="form-group row">
          <label htmlFor="mac" className="col-sm-2 col-form-label newCard-form-text"> MAC </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="mac"
              placeholder="30:A4:F5:20:4C:5A"
              onChange={this.onInputChange}
              name="mac"
              value={this.state.board.mac}
              disabled={!kiconex_editable} />
            {errors.mac.length > 0 && <span className='boardFormError'>{errors.mac}</span>}
          </div>
        </div>

        {/* Fecha Placa */}
        <div className="form-group row">
          <div className="col newCard-form-text"> {lang.handle('Fecha fabricación')} </div>
          <div className="col-sm-10">
            <DatePicker
              className="form-control"
              value={dayjs(moment(this.state.board.date, "DD-MM-YYYY"))}
              onChange={this.onChangeDate}
              format="DD-MM-YYYY"
              disabled={!kiconex_editable}
              required />
          </div>
        </div>

        <div className="form-group row">
          {/* Modelo de la placa */}
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Modelo placa')} </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="model"
              value={this.state.board.model}
              disabled={!kiconex_editable}
              required>
              <option value="Kibox0"> Kibox0 </option>
              <option value="Kibox1"> Kibox1 </option>
              <option value="Kibox1A"> Kibox1A </option>
              <option value="Kibox1E"> Kibox1E </option>
              <option value="Kibox2" defaultValue> Kibox2 </option>
              <option value="Kibox2A"> Kibox2A </option>
              <option value="Kibox4"> Kibox4 </option>
              <option value="Kibox8"> Kibox8 </option>
              <option value="Kibox-Lite"> Kibox-Lite </option>
              <option value="Kibox-CAN"> Kibox-CAN </option>
            </select>
          </div>

          {/* USB */}
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Tipo USB')} </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="usb"
              value={this.state.board.usb}
              disabled={!kiconex_editable}
              required>
              <option value="Ninguno"> {lang.handle('Ninguno')} </option>
              <option value="Chino"> {lang.handle('Chino')}</option>
              <option value="FTDI" defaultValue> FTDI </option>
              <option value="USB-COM4"> USB-COM4 </option>
              <option value="SHIELD-V1.0.1-2P"> SHIELD-V1.0.1-2P </option>
              <option value="SHIELD-V1.0.1-4P"> SHIELD-V1.0.1-4P </option>
              <option value="SHIELD-V1.0.1-CAN"> SHIELD-V1.0.1-CAN </option>
            </select>
          </div>
        </div>

        {/* Plan de plataforma kiconex */}
        <div className="form-group row">
          <div className="col newCard-form-text"> {lang.handle('Plan kiconex')} </div>
          <div className="col-sm-10">
            <Select
              disabled={!kiconex_editable}
              showSearch
              className="form-control"
              optionFilterProp="ki_subscription_name"
              value={this.state.board.ki_subscription_name}
              placeholder="ki8PRO"
              onSelect={(value, event) => this.onChangeSelect(value, event)}
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              name="ki_subscription_name"
              dropdownRender={options => (
                <div>
                  {options}
                  <Divider style={{ margin: '4px 0' }} />
                </div>
              )}
            >
              {ki_plans.name.map(subscription => (
                <Option type="ki_subscription_name" key={subscription}> {subscription} </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="form-group row">
          {/* Plan de la placa */}
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Plan')} </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="subscription"
              value={this.state.board.subscription}
              disabled={!kiconex_editable}
              required>
              <option value="Basic"> Basic </option>
              <option value="Professional" defaultValue> Professional </option>
              <option value="Premium"> Premium </option>
              <option value="OEM">OEM</option>
            </select>
          </div>

          {/* Nº dispositivos */}
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Nº dispositivos')} </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="devices_number"
              value={this.state.board.devices_number}
              disabled={!kiconex_editable}
              required>
              <option value="2" defaultValue> 2 </option>
              <option value="8"> 8 </option>
              <option value="16"> 16 </option>
              <option value="32"> 32 </option>
              <option value="64"> 64 </option>
              <option value="96"> 96 </option>
              <option value="128"> 128 </option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          {/* Kiwi */}
          <div className="col-sm-2 newCard-form-text"> Kiwi </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="kiwi"
              value={this.state.board.kiwi}
              disabled={!kiconex_editable}>
              <option value="0"> {lang.handle('Ninguno')} </option>
              <option value="1" defaultValue> 1 </option>
              <option value="2"> 2 </option>
              <option value="3"> 3 </option>
              <option value="4"> 4 </option>
              <option value="5"> 5 </option>
              <option value="6"> 6 </option>
              <option value="7"> 7 </option>
              <option value="8"> 8 </option>
              <option value="9"> 9 </option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          {/* Cuadro */}
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Cuadro')} </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="panel"
              value={this.state.board.panel}
              disabled={!kiconex_editable}>
              <option value="0" defaultValue> {lang.handle('No')} </option>
              <option value="1"> {lang.handle('Sí')} </option>
            </select>
          </div>

          {/* Cable */}
          <div className="col-sm-2 newCard-form-text"> <label> {lang.handle('Unidades de cable')} </label> </div>
          <div className="col-sm-4">
            <input className="form-control"
              name="cable"
              value={this.state.board.cable}
              disabled={!kiconex_editable} 
              type="number"
              min="0"
              onChange={this.onInputChange}
              />
          </div>
        </div>

        {/* ICC */}
        <div className="form-group row">
          <label htmlFor="icc" className="col-sm-2 col-form-label newCard-form-text"> ICC </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="icc"
              placeholder="8934076400004135532"
              onChange={this.onInputChange}
              name="icc"
              value={this.state.board.icc}
              disabled={!kiconex_editable} />
            {errors.icc.length > 0 && <span className='boardFormError'>{errors.icc}</span>}
          </div>
        </div>

        {/* ki_router_sn */}
        <div className="form-group row">
          <label htmlFor="ki_router_sn" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Router kiconex')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="ki_router_sn"
              onChange={this.onInputChange}
              name="ki_router_sn"
              value={this.state.board.ki_router_sn}
              disabled={!kiconex_editable} />
            {errors.ki_router_sn.length > 0 && <span className='boardFormError'>{errors.ki_router_sn}</span>}
          </div>
        </div>

        {/* Opcionales */}
        <div className="form-group row">
          <label htmlFor="optionals" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Opcionales')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="optionals"
              placeholder="2 ud. Conector TTL-RS485"
              onChange={this.onInputChange}
              name="optionals"
              value={this.state.board.optionals}
              disabled={!kiconex_editable} />
          </div>
        </div>

        {/* Notas */}
        <div className="form-group row">
          <label htmlFor="notes" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Notas')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="notes"
              placeholder={lang.handle('Este pedido debe salir con una pegatina extra')}
              onChange={this.onInputChange}
              name="notes"
              value={this.state.board.notes}
              disabled={!kiconex_editable} />
          </div>
        </div>

        {/* Estado de fabricación */}
        <div className="form-group row">
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Estado de fabricación')} </div>
          <div className="col-sm-10">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="state"
              value={this.state.board.state}
              disabled={!kiconex_editable}
              required>
              <option value="Fabricado"> {lang.handle('Fabricado')} </option>
              <option value="Asignado" defaultValue> {lang.handle('Asignado')} </option>
              <option value="Terminado"> {lang.handle('Terminado')} </option>
              <option value="Eliminado"> {lang.handle('Eliminado')} </option>
            </select>
          </div>
        </div>
      </div>
    };

    const comercialTab = {
      label: lang.handle('Comercial'), key: '2', children: <div>
        {/* Nombre de placa */}
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Nombre')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="comercial_name"
              onChange={this.onInputChange}
              name="comercial_info.name"
              value={this.state.board.comercial_info.name}
              disabled={!this.props.editable}
            />
          </div>
        </div>

        {/* Cliente final */}
        <div className="form-group row">
          <label htmlFor="final_client" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Cliente final')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="final_client"
              onChange={this.onInputChange}
              name="final_client"
              value={this.state.board.final_client}
              disabled={!this.props.editable}
            />
          </div>
        </div>

        <Divider />

        {/* Numero de pedido comercial */}
        <div className="form-group row">
          <label htmlFor="comercial_order_id" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Pedido')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="comercial_order_id"
              onChange={this.onInputChange}
              name="comercial_info.order_id"
              value={this.state.board.comercial_info.order_id}
              disabled={!this.props.editable}
            />
          </div>
        </div>

        {/* Numero de serie comercial */}
        <div className="form-group row">
          <label htmlFor="comercial_serial_number" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Número de serie')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="comercial_serial_number"
              onChange={this.onInputChange}
              name="comercial_info.serial_number"
              value={this.state.board.comercial_info.serial_number}
              disabled={!this.props.editable}
            />
          </div>
        </div>

        {/* Modelo de equipo comercial */}
        <div className="form-group row">
          <label htmlFor="comercial_model" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Modelo del equipo')} </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="comercial_model"
              onChange={this.onInputChange}
              name="comercial_info.model"
              value={this.state.board.comercial_info.model}
              disabled={!this.props.editable}
            />
          </div>
        </div>

        {/* Estado comercial */}
        <div className="form-group row">
          <div className="col-sm-2 newCard-form-text"> {lang.handle('Estado')} </div>
          <div className="col-sm-10">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="comercial_info.state"
              value={this.state.board.comercial_info.state}
              disabled={!this.props.editable}
              required>
              <option value="Stock" defaultValue> {lang.handle('Stock')} </option>
              <option value="Asignado"> {lang.handle('Asignado')} </option>
              <option value="Terminado"> {lang.handle('Terminado')} </option>
            </select>
          </div>
        </div>

      </div>
    };

    const optionsState = [
      {
        value: STATE_INACTIVE,
        label: 'Inactivo'
      },
      {
        value: STATE_ACTIVE,
        label: 'Activo'
      },
      {
        value: STATE_CANCELLED,
        label: 'Cancelado'
      },
      {
        value: STATE_NOT_APPLICABLE,
        label: 'No procede'
      }
    ];

    const subscriptionsTab = {
      label: lang.handle('Subscripción'), key: '3', children: <div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Estado del plan')} </label>
          <div className="col-sm-10">
            <Select
              className="form-control"
              onChange={this.onChangeKiSubscriptionState}
              name="ki_subscription_state"
              value={this.state.board.ki_subscription_state}
              disabled={!kiconex_editable}
              options={optionsState}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Fecha primera conexión')} </label>
          <div className="col-sm-10">
            <DatePicker
              className="form-control"
              value={this.state.board.ki_connection_date ? dayjs(moment(this.state.board.ki_connection_date, "DD-MM-YYYY")) : null}
              onChange={this.onChangeDateConnection}
              format="DD-MM-YYYY"
              disabled={!kiconex_editable}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Fecha última renovación')} </label>
          <div className="col-sm-10">
            <DatePicker
              className="form-control"
              value={this.state.board.renewal_date ? dayjs(moment(this.state.board.renewal_date, "DD-MM-YYYY")) : null}
              onChange={this.onChangeDateRenewal}
              format="DD-MM-YYYY"
              disabled={!kiconex_editable}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Intervalo de renovación (años)')} </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              onChange={this.onInputChange}
              name="renewal_interval"
              value={this.state.board.renewal_interval}
              disabled={!kiconex_editable || this.state.board.ki_subscription_state === STATE_NOT_APPLICABLE}>
              <option value="0"> {lang.handle('Ninguno')} </option>
              <option value="1"> 1 </option>
              <option value="2"> 2 </option>
              <option value="3"> 3 </option>
              <option value="4"> 4 </option>
              <option value="5"> 5 </option>
              <option value="6"> 6 </option>
              <option value="7"> 7 </option>
              <option value="8"> 8 </option>
              <option value="9"> 9 </option>
            </select>
          </div>
        </div>

        <Divider />

        {SUBSCRIPTIONS.map((value, index) => {
          return <div key={value}>
            <h3>{value.toUpperCase()}</h3>
            <div className="form-group row">
              <label htmlFor={"state_" + value} className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Estado')} </label>
              <div className="col-sm-10">
                <Select
                  className="form-control"
                  onChange={(state) => this.onChangeSubscriptionExtraState(value, state)}
                  name={"state_" + value}
                  value={this.state.board.subscriptions_extra[value]?.state ?? null}
                  disabled={!kiconex_editable}
                  options={optionsState}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor={"hiring_date_" + value} className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Fecha primera contratación')} </label>
              <div className="col-sm-10">
                <DatePicker
                  name={"hiring_date_" + value}
                  className="form-control"
                  value={this.state.board.subscriptions_extra[value] && this.state.board.subscriptions_extra[value].hiring_date ? dayjs(moment(this.state.board.subscriptions_extra[value].hiring_date, "DD-MM-YYYY")) : null}
                  onChange={(date, dateString) => { this.onChangeDateSubscriptions(date, dateString, value, 'hiring_date') }}
                  format="DD-MM-YYYY"
                  disabled={!kiconex_editable}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor={"renewal_date_" + value} className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Fecha última renovación')} </label>
              <div className="col-sm-10">
                <DatePicker
                  name={"renewal_date_" + value}
                  className="form-control"
                  value={this.state.board.subscriptions_extra[value] && this.state.board.subscriptions_extra[value].renewal_date ? dayjs(moment(this.state.board.subscriptions_extra[value].renewal_date, "DD-MM-YYYY")) : null}
                  onChange={(date, dateString) => { this.onChangeDateSubscriptions(date, dateString, value, 'renewal_date') }}
                  format="DD-MM-YYYY"
                  disabled={!kiconex_editable}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-2 col-form-label newCard-form-text"> {lang.handle('Intervalo de renovación (años)')} </label>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  onChange={this.onInputChange}
                  name={"subscriptions_extra." + value + ".renewal_interval"}
                  value={this.state.board.subscriptions_extra[value].renewal_interval}
                  disabled={!kiconex_editable || this.state.board.subscriptions_extra[value].state === STATE_NOT_APPLICABLE}>
                  <option value="0"> {lang.handle('Ninguno')} </option>
                  <option value="1"> 1 </option>
                  <option value="2"> 2 </option>
                  <option value="3"> 3 </option>
                  <option value="4"> 4 </option>
                  <option value="5"> 5 </option>
                  <option value="6"> 6 </option>
                  <option value="7"> 7 </option>
                  <option value="8"> 8 </option>
                  <option value="9"> 9 </option>
                </select>
              </div>
            </div>
          </div>
        })}
      </div>
    };

    const items = [];

    //check de privilegios, para todas las tabs
    if (true) {
      items.push(kiconexTab)
    }

    //check de privilegios, para todas las tabs
    if (true) {
      items.push(comercialTab)
    }

    //check de privilegios, para todas las tabs
    if (true) {
      items.push(subscriptionsTab)
    }
    const loading = this.state.loading;
    return (
      <div className="bg-white card">
        {loading && <Loading loading={loading} />}
        {/* Titulo de la tarjeta */}
        <h4 className="card-title p-3"> {title} </h4>

        {/* Formulario con los datos a cumplimentar */}
        <form onSubmit={this.onSubmit} className="p-3">
          <Tabs
            type="card"
            items={items}
          />
          <Save save={this.props.editable} />
        </form>
      </div>
    )
  }
}

export default withLocale(withNavigation(BoardCard));
