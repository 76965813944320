import { ACCESS_TOKEN, REFRESH_TOKEN, PRIVILEGES_TOKEN } from "../utils/constants";
import { jwtDecode } from "jwt-decode";
import { logout } from './user';

const URL = process.env.REACT_APP_DB_URL_TOKEN;

function willExpireToken(token) {
  const seconds = 60;
  const metaToken = jwtDecode(token);
  const { exp } = metaToken;
  //const expDate = exp - 1000000000;
  const now = (Date.now() + seconds) / 1000;
  return now > exp;
}

export function getAccessToken() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken || accessToken === "null") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
}

export function getRefreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken || refreshToken === "null" || refreshToken === "undefined") {
    return null;
  }

  return willExpireToken(refreshToken) ? null : refreshToken;
}

export function refreshAccessTokenApi(refreshTokenParam) {
  const url = URL + "/rat";
  const bodyObjt = {
    refreshToken: refreshTokenParam,
  };
  const params = {
    method: "POST",
    body: JSON.stringify(bodyObjt),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status !== 200) {
        return null;
      }
      return response.json();
    })
    .then((result) => {
      if (!result) {
        logout();
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        window.location.reload();
      }
    });
}

export function getTokenInfo() {
  var tokenInfo = {
    is_admin: '',
    role_uuid: '',
    tenant_uuid: '',
    privileges: [],
  }
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken || accessToken === "null") {
    return tokenInfo;
  } else {
    return jwtDecode(accessToken);
  }
}

export function hasPrivilege(privilege) {
  const privilegesToken = localStorage.getItem(PRIVILEGES_TOKEN).split(',');
  return privilegesToken.includes(privilege);
}
